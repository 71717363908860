export const CLI_COMMANDS = [
  "git show HEAD~1",
  "git commit -m 'Refactoring in progress'",
  "git clean",
  "kubectl logs -f -l app.kubernetes.io/name=batch | tee batch.log",
  "sudo pacman -Qi borg",
  "notes search ProxyPass",
  "pass -c pro/believeit/production-04/admin",
  "git rebase -i 5ad68603",
  "git branch -D feature/better-dashboard",
  "ava-cli end-to-end-tests staging",
  "ava-cli deploy staging 5c9e9d5e",
  "git lg",
  "z projects",
  "sudo umount /dev/sdc1",
  "sudo dd if=manjaro-xfce-20.2-201203-linux59.iso of=/dev/sdc1 bs=4096 && sync",
  "git checkout -b doc/deployment",
  "git push --set-upstream origin doc/deployment",
  "mkdir infrastructure",
  "chmod +x k3s-install.sh",
  "k3s kubectl get all",
  "kubectl get all --all-namespaces",
  "terraform plan",
  "terraform apply",
  "curl -sfL https://get.k3s.io -o k3s-install.sh",
  "helm list",
  "kustomize build . | kubectl apply -f -",
  "helm upgrade -f production-values.yaml prometheus ./prometheus",
  "sudo systemctl status k3s",
  "sudo systemctl status sshd",
  "ss -ltnp",
  "rsync -avz mtp://Google_Pixel_3a_/ backup-pixel-2020-11-20",
  "pass generate -c pro/believeit/production-03/smtp-su",
  "sudo pacman -S ddclient",
  "sudo pacman -S sslh",
  "git clone https://github.com/k3s-io/helm-controller",
  "kubectl create -f applications/nextcloud",
  "kubectl get charts",
  "kubectl get ingressroutes shaarli -o yaml",
  "ranger /etc",
  "kubectl get cm traefik -o yaml",
  "kubectl config set-context --current --namespace=gitea",
  "sudo chown -R remi:remi data",
  "mvn clean",
  "sbt new playframework/play-scala-seed.g8",
  "java -jar target/scala-2.13/play-scala-seed_2.13-1.0-SNAPSHOT.jar",
  "mvn package -Pnative",
  "npx create-react-app frontend --template typescript",
  "yay -S intellij-idea-ultimate-edition",
  "tig show HEAD~2",
  "tig diff master",
  "mvn quarkus:dev",
  "lerna run build",
  "lerna bootstrap",
  "n 14",
  "yarn run test",
  "docker run -ti node:14 bash",
  "npm run coverage",
  "yarn add -D node-sass",
  "rm -rf backend",
  "tsc --init",
  "docker system prune -af",
  "git-crypt unlock ~/git-secrets/pro/believeit/production-01",
  "yarn add mocha chai @types/mocha @types/chai source-map-support",
  "cd infrastructure",
  "npm i -D prettier",
  "git push --set-upstream origin feature/first-home-view",
  "abc-cli clean-build",
  "kubectl delete pod ava-test-rabbitmq",
  "pkill chromium",
  "wget https://docs.mapbox.com/help/data/stations.geojson",
  "ssh-keygen -b 4096 -f ~/.ssh/production-04",
  "ssh-copy-id -f -i ~/.ssh/production-04/id_rsa 192.168.1.14",
  "ssh -o pubkeyauthentication=no 192.168.1.14",
  "git-create-ssh-remote 192.168.1.14 production-04",
  "systemctl status postgres",
  "ssh odroid@192.168.1.14",
  "sudo dmesg",
  "sudo journalctl -fxu sshd",
  "git reset --hard",
  "scp root@192.168.1.1:/home/k3s/.kube/config .",
  "sudo mhwd-kernel -i linux58",
  "ansible -i inventory.cfg bootstrap.yml",
  "sudo sensors-detect",
  "base64 production.key > production.base64.key",
  "tree",
  "git fetch origin feature/deployment",
  "kubectl exec -ti runner-2vsqwsxx-project-15181994-concurrent-0bmgst bash",
  "lspci -k | grep -A 2 -E '(VGA|3D)'",
  "clear",
  "apk update && apk add openssh",
  "sudo apt-get -f install",
  "sudo pacman -Syu",
  "lsusb",
  "lsblk",
  "apt-file list nginx",
  "lshw -enable pci -class display",
  "dig @80.67.169.12 wikipedia.fr",
  "sudo blkid",
  "sudo mount -t vboxsf shared /home/vbox/shared",
  "ip a",
  "7z x h2.zip",
  "type kgaa",
  "nmap 213.186.33.18 -p 1-200",
  "lsb_release -a",
  "sudo -iu smtp-su",
  "tar -tvf archive.tar",
  "xdg-open http://localhost:8080",
  "fdisk /dev/sdc",
  "docker search mariadb",
  "docker",
  "ssh -L 2000:localhost:80 ec2@lila.production",
  "getent group docker",
  "sudo usermod -aG docker",
  "unset CYPRESS_CACHE_FOLDER",
  ":(){ :|:&};:",
  "reset",
  "dpkg-query -L tig",
  "sudo readlink -f /proc/1957/exe",
  "gpg --armor --output secret.gpg --symmetric secrets.txt",
  "iw list | less",
  "fortune | lolcat",
  "setxkbmap fr",
  "sudo ufw status verbose",
  "nyancat",
  "less /var/log/syslog",
  "pip install --upgrade pip",
  "gradle tasks",
  "gradle test",
  "kubectl get events",
  "pip3 freeze > requirements.txt",
  "netplan try",
  "vagrant destroy --force",
  "find secrets -type f -exec shred -fvu {} \\;",
  "docker rmi $(docker images -aq)",
  "docker volume inspect test-rabbitmq",
  "openssl rand -hex 128",
  "vagrant global-status --prune",
  "gco HEAD frontend-commons/babel.config.js",
  "gss | wc -l",
  "gcl https://github.com/hanreev/types-ol.git",
  'gc -m "Refactor routes"',
  "helm upgrade abc-map-staging /home/remo/projects/abcmap/abc-map-2",
  "gcb feature/user-profile",
  'gc -m "Start new branch, add user account view"',
  "yay -S hunter",
  "kill 9428",
  "lerna run start:e2e --parallel",
  "curl --head https://staging.abc-map.fr/api/health",
  "k logs -f pod/kube-scheduler-master0",
  "k logs -f pod/kube-apiserver-master0",
  "k logs -f pod/metrics-server-678f4bf65b-5wnb8",
  "wget https://projectcontour.io/quickstart/contour.yaml",
  'gc -m "Improve contour deployment"',
  "k logs -f contour-6b5cf4dcb8-xjfpg",
  "k logs -f pod/contour-6b5cf4dcb8-xjfpg",
  "k logs -f pod/kube-controller-manager-master0",
  'gc -m "Improve known hosts"',
  "ansible-playbook -i inventory.cfg site.yaml -t addons",
  "cd kubeadm-ansible/",
  "cd oauth",
  "npm",
  "vagrant list",
  "vagrant status",
  "vagrant ssh k8s-m1",
  "ava i",
  "ansible-playbook site.yaml",
  "./hack/setup-vms.sh",
  "vagrant destroy -f",
  "vim ~/.ssh/config",
  "ssh-copy-id root@192.168.56.11",
  "ssh-copy-id root@192.168.56.12",
  "ssh-copy-id root@192.168.56.13",
  "export KUBECONFIG=/home/remo/.kube/config/192.168.54.12/root/.kube/config",
  "k logs -f pod/kube-flannel-ds-mr7sj",
  "ansible-playbook -i inventory.cfg reset-site.yaml",
  "ansible-playbook -i inventory.cfg site.yaml",
  "k logs -f pod/kube-flannel-ds-mhvk4",
  "k logs -f pod/metrics-server-678f4bf65b-fnbcb",
  "k describe pod/metrics-server-678f4bf65b-fnbcb",
  'gc -m "Fix deployments"',
  "k delete pod pod/metrics-server-678f4bf65b-fnbcb",
  "k delete pod/metrics-server-678f4bf65b-fnbcb",
  "k logs -f pod/metrics-server-678f4bf65b-mn2xj",
  "kubectl delete pod pod/metrics-server-678f4bf65b-mn2xj",
  "kubectl delete pod/metrics-server-678f4bf65b-mn2xj",
  "kubectl logs -f pod/metrics-server-678f4bf65b-cxvj2",
  "kubectl describe apiservice v1beta1.metrics.k8s.io",
  "k logs -f pod/kube-apiserver-vm-0",
  "k logs -p -f pod/kube-apiserver-vm-0",
  "ansible-playbook -i inventory.cfg site.yaml -t metrics-server",
  "k logs -f pod/metrics-server-658867cdb7-tqzpp",
  "k describ pod/metrics-server-658867cdb7-tqzpp",
  "k describe pod/metrics-server-658867cdb7-tqzpp",
  'gc -m "Fix metrics server"',
  'gc -m "Remove useless files"',
  "cd vagrant",
  "cd ubuntu-20-single-node",
  "z single",
  "ssh-copy-id root@192.168.56.50",
  "ssh-copy-id -o pubkeyauthentication=no root@192.168.56.50",
  'gc -m "Add single node setup"',
  "k get node",
  "kubectl taint nodes --all node-role.kubernetes.io/master-\n",
  "k logs -f pod/metrics-server-658867cdb7-pnrwh",
  "k describe pod/metrics-server-658867cdb7-pnrwh",
  "kubectl taint nodes --all node-role.kubernetes.io/control-plane-\n",
  'gc -m "Improve setup"',
  "cd ~/projects",
  "gcl https://github.com/kubernetes-sigs/kubespray",
  "cd kubespray",
  "ga README.md",
  'gc -m "Improve readme"',
  "ansible-playbook -i environments/single-node/inventory.cfg shutdown.yaml",
  "ksn projectcontour",
  "k logs -f pod/contour-6b5cf4dcb8-k7m5l",
  "k logs -p -f pod/contour-6b5cf4dcb8-k7m5l",
  "ping 192.168.56.50",
  "vagrant ssh",
  'gc -m "Add firewall"',
  "vagrant destroy -f && vagrant up",
  "ssh root@192.168.56.50",
  "vim",
  "ssh-copy-id -f -o pubkeyauthentication=no root@192.168.56.50",
  'gc -m "Improve firewall, wip"',
  "cd vagrant/ubuntu-20-single-node",
  "cd ../..*",
  "ansible-playbook -i environments/single-node/inventory.cfg bootstrap.yaml -t firewall",
  'gc -m "Improve config"',
  "vagrant destroy",
  'gc -m "Improve vms"',
  "wget https://projectcontour.io/examples/httpbin.yaml",
  "cd ../roles/k8s-contour/templates",
  "wget https://github.com/jetstack/cert-manager/releases/download/v1.5.4/cert-manager.yaml",
  "dig www.abc-map.fr",
  "kubectl logs -p pod/kube-apiserver-multi-node-vm-0 -n kube-system",
  "k get pods -n kube-system",
  "k describe pod kube-flannel-ds-n4c5r",
  "k logs -p pod/coredns-6d4b75cb6d-2fqpr",
  "kubectl logs -f pod/kube-flannel-ds-7tcct -n kube-system",
  "k logs -f pod/etcd-multi-node-vm-0",
  "gcl https://github.com/kairen/kubeadm-ansible",
  "ksn kube-system",
  "k logs -f pod/kube-flannel-ds-djxcn",
  "k logs -f pod/kube-flannel-ds-djxcn kube-flannel",
  "k delete -f example-app/httpbin.yaml",
  "pacman -Qi ansible",
  "curl --connection-timeout 3 192.168.56.11:81",
  "gcloud compute instances list",
  "gcloud compute instances delete vm-0",
  "kubeadm token generate",
  "wget https://github.com/containerd/containerd/releases/download/v1.6.6/containerd-1.6.6-linux-amd64.tar.gz",
  "ss -ltnp",
  "source ~/bin/android-env.sh",
  "yarn run android",
  "yarn run test:interactive",
  "xdg-open .",
  "cd ~/Documents",
  "git rebase --abort",
  "git rebase -i 85ec6fd51",
  "git rebase --continue",
  "gb -D feature/improve-deliveries",
  "git remote prune origin",
  "ava s",
  "prettier --write .",
  "gcloud compute ssh --zone europe-west9-a vm-0",
  "gcloud compute scp -r --zone europe-west9-a . vm-0:/home/remo/k8s",
  "ip route -6",
  "yay -S bun",
  "docker system prune -a",
  "k logs -f --tail 1000 -l app.kubernetes.io/name=abc-map-server",
  "watch kubectl get all --all-namespaces",
  "sudo reboot now",
  "sudo pacman -Syu",
  "git-crypt lock --all -f",
  "k top nodes",
  "yay -R python2",
  "gcloud components install gke-gcloud-auth-plugin",
  "yay -S google-cloud-sdk-gke-gcloud-auth-plugin",
  "yarn run build:package",
  "sudo pacman -S lutris",
  "ip a",
  "ncdu",
  "cd dist",
  "python -m http.server 8000",
  "./scripts/ci.sh",
  "rm yarn.lock",
  'gc -m "Improve UI"',
  "byobu",
  "z perso web",
  "gl",
  "npm run start",
  "ga -A",
  "gss",
  'gc -m "Improve UI"',
  "git clean",
  "clear",
];
