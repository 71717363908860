.container {
  display: flex;
  flex-direction: column;
}

.tagLine {
  margin: 0 0 1rem 0;
}

.link {
  margin-left: 1rem;
}

.linkGroup {
  margin-bottom: 1rem;
}
