{
  "App": {
    "Remi_Pace_software_engineer": "Rémi Pace, software engineer",
    "Hello": "Hello",
    "My_name_is_remi": "My name is Rémi,",
    "I_am_software_engineer": "I am a software engineer"
  },
  "Links": {
    "A_project_idea": "A project idea ?",
    "Want_to_see_more": "Want to see more ?",
    "Github_profile": "Github profile",
    "Gitlab_profile": "Gitlab profile",
    "Linkedin_profile": "Linkedin profile",
    "Curriculum_vitæ_pdf": "Curriculum vitae (PDF)"
  },
  "Terminal": {
    "Terminal_emulator": "Terminal emulator"
  }
}
