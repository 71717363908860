@import "src/mixins";

.window {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  border-radius: 0.3rem;
  box-shadow: 0.5rem 0.5rem 0.5rem #888888;

  font-family: monospace;
  background: #2c3e50;

  overflow: hidden;

  a {
    color: white;
    text-decoration: underline;
  }

  a:visited {
    color: lightgray;
    text-decoration: underline;
  }
}

.topBar {
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  height: 2.5rem;

  .title {
    flex-grow: 1;
    color: #fff;
    text-align: center;
    font-size: 0.9rem;
  }

  .button {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    margin: 0.3rem;
    border: none;
  }

  .close {
    background: #e74c3c;
  }

  .maximize {
    background: #f1c40f;
  }

  .minimize {
    background: #2ecc71;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  height: 100%;
  max-height: 100%;

  overflow-x: hidden;
  overflow-y: auto;

  background: #34495e;
  color: #fff;
  border-radius: 0.3rem;
  padding: 1rem;
}
